import * as React from "react"
import Layout from "../components/global/Layout"
import profile from "../data/profile.json"
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt"
import { RiMailFill } from "@react-icons/all-files/ri/RiMailFill"
import { IoMdPin } from "@react-icons/all-files/io/IoMdPin"
import Form from "../components/templates/Form"
import { IconContext } from "@react-icons/all-files"
import Seo from "../components/global/Seo"

export default function Contact() {
    return (
        <>
            <Layout>
                <Seo
                    title="Contact CPA Buy & Sell"
                    description="Our team is here to help with any questions you may have."
                />
                <div className="section">
                    <div className="container">
                        <div className="md:flex flex-none gap-10">
                            <div className="md:w-1/2">
                                <div>
                                    <h2 className="h2 font-bold">Contact Information</h2>
                                    <p>Our team is here to help with any questions you may have.</p>
                                    <ul className="mt-10">
                                        {profile.contact_information.office &&
                                            <li>
                                                <IconContext.Provider value={{ color: '#004E74', size: '25px' }}><FaPhoneAlt className="inline mr-3 my-4" /></IconContext.Provider><a href={`tel: ${profile.contact_information.office}`}>{profile.contact_information.office}</a>
                                            </li>
                                        }
                                        {profile.contact_information.email &&
                                            <li>
                                                <IconContext.Provider value={{ color: '#004E74', size: '25px' }}><RiMailFill className="inline mr-3 my-4" /></IconContext.Provider><a href={`mailto: ${profile.contact_information.email}`}>{profile.contact_information.email}</a>
                                            </li>
                                        }
                                        {profile.contact_information.address &&
                                            <li>
                                                <IconContext.Provider value={{ color: '#004E74', size: '30px' }}><IoMdPin className="inline mr-3 my-4" /></IconContext.Provider>
                                                <span>{profile.contact_information.address} {profile.contact_information.city} {profile.contact_information.state}, {profile.contact_information.zip_code}</span>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="md:w-1/2">
                                <div>
                                    <div className="bg-slate-50 p-10">
                                        <h2 className="font-bold">Fill out the form below and we'll be in touch</h2>
                                        <Form 
                                    source="https://www.cpabuysell.com"
                                    formName="Website Inquiry"
                                    subject="Website Contact Form"
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}